import * as React from 'react';
import { montserrat } from './montserrat-span';

import { cn } from '@/lib/utils';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, type, ...props }, ref) => {
  return (
    <input
      type={type}
      className={cn(
        `${montserrat.className} w-full h-8 border-b border-black p-2 text-light 
                  bg-transparent text-sm outline-none placeholder:text-dark-grey rounded-none`,
        className
      )}
      ref={ref}
      {...props}
    />
  );
});
Input.displayName = 'Input';

export { Input };
