import { cn } from '@/lib/utils';

const Button = ({
  type,
  children,
  onClick,
  className,
  disabled = false,
  id
}: {
  type?: 'submit' | 'reset' | 'button' | undefined;
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
  id?: string;
}) => {
  return (
    <button className={cn('group relative py-1.5 text-xl font-medium z-10', className)} onClick={onClick} type={type} disabled={disabled} id={id}>
      {children}
      <span
        className={
          'absolute bottom-0 left-0 w-full h-[3px] group-hover:w-[calc(100%+10px)] group-hover:-left-[5px] group-hover:h-full group-hover:transition-all duration-500 bg-light-green -z-10'
        }
      ></span>
    </button>
  );
};

export default Button;
