'use client';

import Button from '@/components/website/elements/button';
import { scrollToAnchor } from '@/lib/utils';
import { usePathname, useRouter } from '@/navigation';
import { useLocale, useTranslations } from 'next-intl';
import Image from 'next/image';
import Link from 'next/link';

import { useState } from 'react';

const NavMenu = () => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const t = useTranslations('Common');
  const router = useRouter();
  const currentLocale = useLocale();
  const pathname = usePathname();

  const handleClick = () => {
    handleCloseMenu();
    scrollToAnchor('footer');
  };

  const handleOpenMenu = () => {
    setIsMenuVisible(true);
    document.body.style.overflow = 'hidden';
  };

  const handleCloseMenu = () => {
    setIsMenuVisible(false);
    document.body.style.overflow = 'auto';
  };

  return (
    <div>
      <button onClick={handleOpenMenu}>
        <Image
          src={'/img/icons/burger.svg'}
          alt={'Open Menu'}
          width={60}
          height={35}
          className='w-11 md:w-16 max-h-8 md:max-h-none hover:scale-110 transition-all duration-200'
        />
      </button>
      <div
        className={`fixed top-0 ${
          isMenuVisible ? 'left-0' : '-left-full delay-300'
        } w-screen h-screen overflow-hidden z-50`}
      >
        <div
          className={`${
            isMenuVisible ? 'block' : 'hidden'
          } absolute top-0 left-0 w-screen h-screen bg-transparent-dark-grey cursor-pointer`}
          onClick={handleCloseMenu}
        ></div>
        <div
          className={`absolute top-0 -left-full flex flex-col p-10 md:pl-24 md:pr-10 md:py-20 bg-black w-screen md:min-w-96 md:w-2/5 h-screen transition-all duration-300 overflow-y-auto md:overflow-y-hidden`}
          style={isMenuVisible ? { left: 0 } : {}}
        >
          <button onClick={handleCloseMenu} className='group flex justify-between md:justify-start items-center'>
            <Link href={'/'} className='block md:hidden'>
              <Image
                src={'/img/logotype-white.svg'}
                alt={'Logotype'}
                width={150}
                height={70}
                className='max-h-12 md:max-h-none w-auto h-12'
                priority={false}
              />
            </Link>
            <Image
              src={'/img/icons/close.svg'}
              alt={'Close Menu'}
              width={50}
              height={50}
              className='max-w-9 md:max-w-none max-h-9 md:max-h-none group-hover:rotate-90 transition-all duration-200'
              priority={false}
            />
          </button>
          <hr className='mt-12 mb-20' />
          <nav>
            <ul className='flex flex-col gap-8'>
              <li>
                <Link
                  href={'/events'}
                  locale={currentLocale}
                  onClick={handleCloseMenu}
                  className={'text-white hover:text-light-green transition-all duration-200 text-5xl font-bold'}
                >
                  {t('events')}
                </Link>
              </li>
              <li>
                <Link
                  href={'/merch'}
                  locale={currentLocale}
                  onClick={handleCloseMenu}
                  className={'text-white hover:text-light-green transition-all duration-200 text-5xl font-bold'}
                >
                  {t('merch')}
                </Link>
              </li>
            </ul>
          </nav>
          <div className='flex flex-col justify-between h-[calc(100%-50px)] md:h-full'>
            <div className='mt-24'>
              <Button onClick={handleClick} className={'text-white hover:text-black'} id={'contactUs'}>
                {t('contactUs')}
              </Button>
            </div>
            <div className='mb-24 md:mb-0 flex gap-8'>
              <button
                onClick={() =>
                  router.replace(`/${pathname}`, {
                    locale: 'uk',
                  })
                }
                className='flex items-center gap-1 bg-none text-white hover:text-light-green text-xl font-bold transition-all duration-200'
              >
                <span
                  className={`${currentLocale === 'uk' ? 'block' : 'hidden'} h-2.5 w-2.5 bg-light-green rounded-full`}
                ></span>
                ua
              </button>
              <button
                onClick={() =>
                  router.replace(`/${pathname}`, {
                    locale: 'en',
                  })
                }
                className='flex items-center gap-1 bg-none text-white hover:text-light-green text-xl font-bold transition-all duration-200'
              >
                <span
                  className={`${currentLocale === 'en' ? 'block' : 'hidden'} h-2.5 w-2.5 bg-light-green rounded-full`}
                ></span>
                eng
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavMenu;
