import { cn } from '@/lib/utils';
import React from 'react';
import { montserrat } from './montserrat-span';

export interface TextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(({ className, ...props }, ref) => {
  return (
    <textarea
      className={cn(
        `${montserrat.className} w-full border-b border-black p-2 text-light bg-transparent
      text-sm outline-none placeholder:text-dark-grey`,
        className
      )}
      ref={ref}
      {...props}
    ></textarea>
  );
});
Textarea.displayName = 'Textarea';

export { Textarea };
