'use client';

import { ChevronUp } from 'lucide-react';
import { useEffect, useState } from 'react';

const ScrollTop = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      setShowButton(scrollTop > 500);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div
      className={`fixed bottom-5 right-5 ${
        showButton ? 'opacity-100' : 'opacity-0'
      } transition-opacity duration-200 bg-light-green rounded-full p-2 cursor-pointer hover:scale-110`}
      onClick={scrollToTop}
    >
      <ChevronUp size={35} strokeWidth='1.5' className='stroke-black' />
    </div>
  );
};

export default ScrollTop;
