'use client';

import { FormValues } from '@/root/types';
import { useTranslations } from 'next-intl';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { ZodError, z } from 'zod';
import Button from '../elements/button';
import FormError from '../elements/form-error';
import { Input } from '../elements/input';
import { Textarea } from '../elements/textarea';

const schema = z.object({
  fullName: z.string().min(1),
  email: z.string().email().min(1),
  company: z.string().min(1),
  phone: z.string().min(1),
  description: z.string(),
});

const errorMessages: Record<string, string> = {
  fullName: "Ім'я повинно бути вказане",
  email: 'Введіть коректну електронну адресу',
  company: 'Назва компанії повинна бути вказана',
  phone: 'Введіть коректний номер телефону',
  description: 'Опис повинен бути вказаний',
  invalid_type: 'Неправильний тип поля',
  required: "Поле є обов'язковим",
};

const zodErrorTranslations: Record<string, string> = {
  invalid_type: 'Недопустимий тип',
  too_small: 'Значення занадто мале',
  too_big: 'Значення занадто велике',
  invalid_string: 'Недопустимий рядок',
  invalid_email: 'Недопустимий email',
  invalid_literal: 'Недопустиме значення',
};

const ContactForm = ({ handleSubmit }: { handleSubmit: (formData: FormValues) => void }) => {
  const {
    register: registerInput,
    handleSubmit: onSubmitForm,
    formState: { errors },
    reset,
  } = useForm<FormValues>();
  const [zodErrors, setZodErrors] = useState<Record<string, string[]>>({});
  const [isLoading, setIsLoading] = useState(false);

  const translateZodError = (error: ZodError) => {
    const fieldErrors: Record<string, string[]> = {};
    error.errors.forEach((err) => {
      const translatedError = zodErrorTranslations[err.code] || err.message;
      const fieldName = err.path[0];
      fieldErrors[fieldName] = fieldErrors[fieldName] || [];
      fieldErrors[fieldName].push(translatedError);
    });
    return fieldErrors;
  };

  const onSubmitHandler: SubmitHandler<FormValues> = async (data) => {
    setIsLoading(true);
    try {
      await schema.parseAsync(data);
      handleSubmit(data);
      toast.success('Дякуємо! Ваш запит успішно відправлено.');
      reset();
    } catch (error) {
      if (error instanceof ZodError) {
        const translatedErrors = translateZodError(error);
        setZodErrors(translatedErrors);
      } else {
        console.error('Validation failed:', error);
      }
    }
    setIsLoading(false);
  };

  const t = useTranslations('Form');
  return (
    <form className='flex flex-col gap-2.5' id={'contactForm'} onSubmit={onSubmitForm(onSubmitHandler)}>
      <div className='flex flex-wrap flex-col md:flex-row gap-5 md:gap-10'>
        <div className='flex-1 md:min-w-52'>
          <Input
            type={'text'}
            autoComplete={'given-name'}
            placeholder={t('fullName')}
            {...registerInput('fullName', { required: true })}
          />
          <div className='min-h-4'>
            {errors.fullName && <FormError message={errorMessages.fullName} />}
            {zodErrors.fullName && zodErrors.fullName.map((error, index) => <FormError key={index} message={error} />)}
          </div>
        </div>
        <div className='flex-1 md:min-w-52'>
          <Input
            type={'email'}
            autoComplete={'email'}
            placeholder={t('email')}
            {...registerInput('email', { required: true })}
          />
          <div className='min-h-4'>
            {errors.email && <FormError message={errorMessages.email} />}
            {zodErrors.email && zodErrors.email.map((error, index) => <FormError key={index} message={error} />)}
          </div>
        </div>
      </div>
      <div className='flex flex-wrap flex-col md:flex-row gap-5 md:gap-10'>
        <div className='relative flex-1 md:min-w-52'>
          <Input
            type={'text'}
            autoComplete={'organization'}
            placeholder={t('company')}
            {...registerInput('company', { required: false })}
          />
          <div className='min-h-4'>
            {errors.company && <FormError message={errorMessages.company} />}
            {zodErrors.company && zodErrors.company.map((error, index) => <FormError key={index} message={error} />)}
          </div>
        </div>
        <div className='flex-1 md:min-w-52'>
          <Input
            type={'text'}
            autoComplete={'tel'}
            placeholder={t('phone')}
            {...registerInput('phone', { required: false })}
          />
          <div className='min-h-4'>
            {zodErrors.phone && zodErrors.phone.map((error, index) => <FormError key={index} message={error} />)}
          </div>
        </div>
      </div>
      <div>
        <Textarea placeholder={t('description')} {...registerInput('description')} rows={1} />
      </div>
      <div className='flex justify-end'>
        <Button type='submit' disabled={isLoading}>
          {t('send')}
        </Button>
      </div>
    </form>
  );
};

export default ContactForm;
