'use client';
import { scrollToAnchor } from '@/lib/utils';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import Link from 'next/link';
import Button from '../elements/button';
import NavMenu from './nav-menu';

const Header = () => {
  const t = useTranslations('Common');
  // TODO: Чекає кращих часів
  // const headerRef = useRef<HTMLElement>(null);

  // useEffect(() => {
  //   let lastScrollTop = 0;
  //   const delta = 5;

  //   const handleScroll = () => {
  //     const st = window.pageYOffset || document.documentElement.scrollTop;
  //     if (Math.abs(lastScrollTop - st) <= delta) return;
  //     if (!headerRef.current) return;
  //     if (st > lastScrollTop && st > headerRef.current.offsetHeight) {
  //       headerRef.current.classList.remove('top-2.5');
  //       headerRef.current.classList.add('-top-48');
  //     } else {
  //       if (st + window.innerHeight < document.documentElement.scrollHeight) {
  //         headerRef.current.classList.remove('-top-48');
  //         headerRef.current.classList.add('top-2.5');
  //       }
  //     }
  //     lastScrollTop = st <= 0 ? 0 : st;
  //   };

  //   window.addEventListener('scroll', handleScroll);

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  const handleClick = () => {
    scrollToAnchor('footer');
  };

  return (
    <header
      // ref={headerRef}
      // className={`fixed top-2.5 left-0 px-5 md:px-24 w-full py-4 z-10 duration-500 ease-in-out`}
      className={`absolute top-0 w-full py-4 px-5 md:px-14 flex flex-row-reverse md:flex-row justify-between items-end z-10`}
      id={'header'}
    >
      <div className='flex-1 flex justify-end md:justify-start items-end'>
        <NavMenu />
      </div>
      <div className='flex-1 flex justify-start md:justify-center items-end'>
        <Link href={'/'}>
          <Image
            src={'/img/logotype.svg'}
            alt={'Logotype'}
            width={150}
            height={70}
            className='max-h-12 md:max-h-none w-auto h-auto'
            priority={true}
          />
        </Link>
      </div>
      <div className='flex-1 hidden md:flex justify-end items-end'>
        <Button onClick={handleClick} id={'contactUs'}>
          {t('contactUs')}
        </Button>
      </div>
    </header>
  );
};

export default Header;
