import { cn } from '@/lib/utils';
import { Montserrat } from 'next/font/google';

export const montserrat = Montserrat({ subsets: ['latin'] });

type FontStyle = 'italic' | 'normal';

const MontserratSpan = ({
  children,
  className,
  style,
}: {
  children: React.ReactNode;
  className?: string;
  style?: FontStyle;
}) => {
  return <span className={cn(`${montserrat.className} ${style ? `!${style}` : ''}`, className)}>{children}</span>;
};

export default MontserratSpan;
